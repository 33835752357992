import React from 'react'
import Link from 'gatsby-link'
import Layout from '../layouts'

const BloodPlantPage = () => (
  <Layout>
    <div>
      <h1 className="page-title">The "Blood Plant"</h1>
      <div className="row">
        <div className="col-md-10 col-md-offset-1">
          <div className="section">
            <blockquote>
              <p>
                Keep in mind that everything you're about to read about "The Blood
                Plant" is no longer a problem in Sibley after the plant shut down and a new business moved in. 
                Sometime in mid-2017, it appears that the plant had decreased
                production or otherwise limited their emissions in a way that made
                the town-wide odors go from a problem that we had to deal with 4-5
                times <em>every week</em>, to one that was only 2-3 times{' '}
                <em>per month</em>.
              </p>
              <p>
                By the early summer of 2018, the odors seemed to have been
                reduced even further, to the point that even <em>I</em> wasn't thinking much about them anymore.
                I can remember only a handful of times that
                I've smelled something bad coming from the area of the plant since
                June 2018.
              </p>
              <p>
                With that said, I'm continuing to track the history, news and
                developments with the "Blood Plant" here on this website.
              </p>
              <p>
                If you're a resident or visiting Sibley and you've detected what
                you think is the odor and fumes I've described below, I'd be{' '}
                <strong>very interested</strong> in hearing from you. Please email
                me at{' '}
                <a href="mailto:joshua@nozzlegear.com">joshua@nozzlegear.com</a>.
                Our correspondence will remain private, you will not be
                identified.
              </p>
            </blockquote>
            <p className="lead">
              In 2013, the Iowa Economic Development Authority gave $150,000 in
              financial assistance to a business that moved into an old factory
              building in Sibley. Not long after the business got up and running,
              locals began to refer to the facility with the not-so-affectionate
              moniker
              <strong> "The Blood Plant"</strong> thanks to the absolutely
              terrible, wretched odors emanating from the building.
            </p>
            <p className="lead">
              We're not just talking an "oh, that was a little stinky" kind of
              smell. We're talking a rotten-blood-mixed-with-stale-beer, "I wonder
              if I should see a doctor after breathing that" wall of fumes. The
              putrid air seemed to hang over much of the town for 3-4 days{' '}
              <em>every week</em>. It was <em>particularly</em> nasty on hot and
              humid summer days.
            </p>
            <p className="lead">
              At the time I lived very close to the offending facility. It was
              absolutely terrible, we couldn't open our windows in the spring,
              summer or fall thanks to the seemingly malignant stench. Driving
              down 10th Street would make you instantly regret using your
              vehicle's air conditioner. And need I mention: the facility is
              located right down the block from Superfoods, the only grocery store
              in town.
            </p>
            <p className="lead">
              <a
                href="http://www.dglobe.com/news/3687679-sibley-residents-breathe-easier-they-hope"
                target="_blank"
                rel="noreferrer"
              >
                One Sibley resident, who lived near the plant at the time,
                regretted purchasing his home due to the "overwhelming aroma"
              </a>. It was so bad for him that he was forced to turn off his air
              conditioner in the summer, and his wife's in-home daycare had
              suffered due to its close proximity to the source of the smell.
            </p>
            <p className="lead">
              After living so close to "The Blood Plant", I eventually grew fed up
              from what I perceived as total inaction from the city council and
              government of Sibley. I resolved to use my skills as a professional
              developer and programmer to create this very website. The goal:
              document as much as I could about the situation, call out the city
              on what, to me, looked to be a streak of inaction and
              ineffectiveness, and <em>hopefully</em> bring about some change.
            </p>
            <p className="lead">
              In fact this website was surprisingly effective at drawing attention
              to the issue with{' '}
              <a href="https://i.imgur.com/I5wmRtH.png" target="_blank" rel="noreferrer">
                over 2000 visitors on just one day in December of 2015
              </a>{' '}
              - largely from the Sibley area alone - and many more since then.
              It's also been a bit of a sticking point for some of the more salty
              in town, culminating in{' '}
              <Link to="/free-speech">
                {' '}
                a misguided attempt to scare me into taking this website down
              </Link>, First Amendment rights to criticize the government be
              damned.
            </p>
            <h3 className="section-header">News & Developments</h3>
            <div className="update">
              <p className="update-header">2021</p>
              <p>
                {"I'm happy to report that the Blood Plant in town shut down and another business has since moved into the facility. At the time of this writing, no foul odors or other unsavory fumes have been detected coming from the factory. News regarding the lawsuit between IDP and the City of Sibley has stopped being reported on, and the state of the lawsuit is unclear. It may have been dropped or settled outside of court."}
              </p>
              <p>
                {"If you're a resident of Sibley and you've smelled strong odors coming from the old Blood Plant facility, please contact me at "}
                <a href="mailto:joshua@nozzlegear.com">{"joshua@nozzlegear.com"}</a>
                {". I would be interested in talking with you, and our correspondence will remain private."}
              </p>
            </div>
            <div className="update">
              <p className="update-header">May 2nd, 2018</p>
              <p>
                In December of 2017, the City of Sibley{' '}
                <a href="https://www.aclu-ia.org/sites/default/files/2-1_ex._a.pdf">
                  sent a threatening letter to me,
                </a>
                demanding that I take down this website or else they would sue me.
                Their letter claimed that this website "libels the City of Sibley,
                interferes with recruitment of businesses and new residents, and
                negatively affects property values". The letter also asserted that
                this website was responsible for the clinic in town losing a
                prospective physician.
              </p>
              <p>
                Fearing a lawsuit that, at the time, I couldn't afford, I updated
                this website to be more fair to the town itself. At the same time,
                I contacted both the American Civil Liberties Union of Iowa, and
                the Electronic Frontier Foundation, asking both organizations for
                help. The legal directors of both organizations reached out, and
                in February of 2018 I, with the help of the ACLU of Iowa, filed a
                lawsuit against the City of Sibley for attempting censorship and
                violating my First Amendment right to criticize the government.
              </p>
              <p>
                One month later, we won and dismissed our lawsuit by settling with
                the City after they agreed to five terms{' '}
                <Link to="/free-speech">(detailed here)</Link>. At that time, and
                despite what the City claimed in their threatening letter, the
                odors from IDP were still a problem in Sibley.{' '}
                <strong>
                  However, I will freely and easily admit that the severity of the
                  problem had been significantly reduced by that point, to the
                  point that I no longer consider it a major nuisance.
                </strong>{' '}
              </p>
              <p>
                Where before you could smell the odors 4-5 times each week, you
                might then have smelled them 2-3 times per month. Now in the early
                summer of 2018, they've been reduced nearly completely.
                <strong>
                  Keep in mind, though, that the "Blood Plant" has sued the City
                  of Sibley for $5.5 million in Federal Court, and I fear if they
                  win they'll go back to polluting the town every day.
                </strong>
              </p>
            </div>
            <div className="update">
              <p className="update-header">February 20th, 2018</p>
              <p>
                Iowa Drying and Processing has sued the City of Sibley for $5.5
                million in Federal court. In their lawsuit, they claim that the
                ordinance the city has used to levy fines against them is
                unconstitutional and arbitrarily enforced.
              </p>
              <p>
                Source:{' '}
                <a href="http://siouxcityjournal.com/news/local/crime-and-courts/plant-owner-sues-sibley-over-odor-ordinance/article_98bde1fd-385f-5143-94d4-1847f7421a22.html">
                  siouxcityjournal.com
                </a>
              </p>
            </div>
            <div className="update">
              <p className="update-header">June 29, 2017</p>
              <p>
                A scant few weeks before the City's court case with IDP was
                scheduled to proceed, both sides have agreed to dismiss their
                lawsuits against eachother. Court records did not indicate the
                reason for dismissal, and{' '}
                <strong>
                  as far as we know there has been NO agreement made about the
                  odor.
                </strong>{' '}
              </p>
              <p>
                Neither the City administrator or City attorney were available for
                comments, nor were any IDP officials available.
              </p>
              <p>
                However, an attorney who works with the City attorney on the case
                commented that both parties had decided to "let [the matter] sit
                for now", and that parties felt that it wasn’t worth spending more
                money to settle an issue that is{' '}
                <a href="https://i.giphy.com/media/oOTTyHRHj0HYY/giphy.gif">
                  "pretty much-taken care of."
                </a>
              </p>
              <p>
                Source:{' '}
                <a href="http://kiwaradio.com/local-news/sibley-and-idp-civil-lawsuits-dismissed/">
                  kiwaradio.com
                </a>.
              </p>
            </div>
            <div className="update">
              <p className="update-header">December 11, 2016</p>
              <p>
                Nearly $40,000 in fines have been issued to the "Blood Plant" by
                the City.
              </p>
              <blockquote>
                <p>
                  Local businessman [...] says he has seen people walking down the
                  street and covering their noses. He says the odor has definitely
                  hurt business in town.
                </p>
              </blockquote>
              <p>
                Source:{' '}
                <a href="http://www.keloland.com/news/article/news/iowa-town-hopes-to-part-with-stinky-feed-manufacturing-firm">
                  keloland.com
                </a>.
              </p>
            </div>
            <div className="update">
              <p className="update-header">November 29, 2016</p>
              <p>
                IDP has been listed for sale for $5.5 million. They have also
                filed a counter-suit against the City, asking that the court
                prevent the City from continuing to cite IDP for nuisance
                violations.
              </p>
              <p>
                The City's court case against IDP has been postponed until July
                2017, with "neither side pushing the issue very hard at this
                point" (despite the smells). The City's attorney says the plant
                has agreed to only do limited drying at the plant "from now on and
                not to do any rendered product".
              </p>
              <p>
                When asked why the case was put off for nearly eight months, the
                City attorney said "there’s no real reason on either side to wait
                until July except that both sides hope the plant will be sold
                before that time".
              </p>
              <p>
                Source:{' '}
                <a href="http://kiwaradio.com/local-news/city-attorney-neither-side-pushing-sibley-smell-issue/">
                  kiwaradio.com
                </a>.
              </p>
            </div>
            <div className="update">
              <p className="update-header">May 5th, 2016</p>
              <p className="lead">
                Following the hearing mentioned below, the Sibley city council has
                issued a ruling on the issue. They have found from the evidence
                presented at the hearing on March 28th that the emissions from the
                IDP property constitute a nuisance under both Sibley city code and
                Iowa state code.
              </p>
              <p className="lead">
                They have found that the plant's emissions are injurious to
                health, indecent, or <strong>unreasonably offensive</strong> to
                the senses, and that IDP <strong>knows</strong> its facilities
                create this odor.
              </p>
              <p className="lead">
                Following the ruling, the City of Sibley had ordered IDP to
                deposit $50,000 into an escrow account held by the City of Sibley,
                which would have been used to hire and pay an engineer to study
                IDP’s operations and make recommendations. The money also would
                have been used to abate any future nuisances.
              </p>
              <p className="lead">
                <strong>However</strong>, IDP had until April 11th, 2016 to
                deposit the funds into the city's escrow account,{' '}
                <strong>but they failed to do so</strong>.
              </p>
              <p className="lead">
                The city council has now authorized City Attorney Harold Dawson to
                file a petition in District Court seeking an injunction that will
                force IDP to abide by the earlier court order.
              </p>
            </div>
            <div className="update">
              <p className="update-header">March 11th, 2016</p>
              <p className="lead">
                The City of Sibley has finally begun fining IDP for the stench in
                the air, but these fines have so far been ineffective. There is
                currently an open case in the Osceola County District Court
                regarding the fines, and a public hearing is scheduled for March
                28th at 9:30 A.M.
              </p>
              <p className="lead">
                If this problem affects you at all,{' '}
                <strong>
                  we strongly encourage you to show up at the public hearing on
                  March 28th to voice your complaints
                </strong>. The hearing will be held in the courthouse in Sibley on{' '}
                <strong>March 28th at 9:30 A.M.</strong>.
              </p>
              <p className="lead">
                In the mean time, please call the Sherrif's Department at{' '}
                <strong>754-2556</strong>, the City of Sibley Office at{' '}
                <strong>754-2541</strong>, or Harold Dawson's office at{' '}
                <strong>754-4601</strong> to make a complaint.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default BloodPlantPage
